import React from "react";
import img1 from "../assets/icons/tg.gif";
import img2 from "../assets/icons/cs1.png";

const Socials = () => {
  return (
    <div className="fixed right-4 top-1/2 z-10 -translate-y-1/2 transform">
      <div className="flex w-full flex-col gap-2 rounded-lg bg-slate-800 bg-opacity-50 p-2">
        <a
          href="https://t.me/coordinationblog002"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center"
        >
          <img
            src={img1}
            alt="tg"
            className="max-h-16 max-w-16 object-contain"
          />
        </a>
        <a
          href="https://vm.daneviolda.com/160q8uvu9bcws0n67yvx3esa52"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center"
        >
          <img
            src={img2}
            alt="cs"
            className="max-h-16 max-w-16 object-contain"
          />
        </a>
      </div>
    </div>
  );
};

export default Socials;
