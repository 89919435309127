import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    ReactPixel.init("774703414799892");
  }, []);

  const trackButtonClick = () => {
    ReactPixel.trackCustom("ButtonClick", {
      buttonName: "Candidate-se agora",
    });
  };

  const handleScroll = () => {
    const scrollThreshold = 200;
    if (window.pageYOffset > scrollThreshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <footer className="fixed bottom-0 h-36 w-full bg-[#1C2222] text-gray-400">
      <div className="mx-auto flex h-full max-w-screen-sm flex-col items-center justify-center gap-4 bg-[#090B0B] px-4 py-6 md:flex-row md:justify-between">
        <p className="order-2 text-sm text-gray-400 md:order-1">
          © 2024 Live Streamer Hiring. All rights reserved.
        </p>
        <a
          href="https://t.me/Managerstreamer"
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackButtonClick}
          className="order-1 block w-full rounded-lg bg-blue-600 px-6 py-3 text-center font-semibold text-white hover:bg-blue-700 md:order-2 md:w-48"
        >
          Candidate-se agora
        </a>
      </div>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=774703414799892&ev=PageView&noscript=1`}
        />
      </noscript>
    </footer>
  );
};

export default Footer;
