import React from "react";
import Hero from "./components/Hero";
import Showcase from "./components/Showcase";
import Socials from "./components/Socials";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div className="h-full bg-[#1C2222]">
      <div className="mx-auto flex min-h-screen max-w-screen-sm flex-col bg-[#121616] shadow-lg">
        <Socials />
        <Hero />
        <Showcase />
      </div>
      <Footer />
    </div>
  );
};

export default App;
