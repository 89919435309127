import React, { useEffect, useRef, useState } from "react";
import ReactPixel from "react-facebook-pixel"; // Import react-facebook-pixel
import videoClip from "../assets/video/clip-low.webm";

const Spinner = () => (
  <div className="flex aspect-square items-center justify-center">
    <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-gray-900"></div>
  </div>
);

const Hero = () => {
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ReactPixel.init("774703414799892");

    const video = videoRef.current;

    const handleCanPlayThrough = () => {
      setLoading(false);
    };

    if (video) {
      video.addEventListener("canplaythrough", handleCanPlayThrough);
      return () => {
        video.removeEventListener("canplaythrough", handleCanPlayThrough);
      };
    }
  }, []);

  const trackButtonClick = () => {
    ReactPixel.trackCustom("ButtonClick", {
      buttonName: "Candidate-se agora",
    });
  };

  return (
    <section className="text-slate-200">
      {/* Video */}
      <div className="relative bg-black">
        {loading && <Spinner />}
        <video
          className={`aspect-square h-full w-full ${loading ? "hidden" : "block"}`}
          autoPlay
          loop
          muted
          playsInline
          src={videoClip}
          ref={videoRef}
          onLoadedData={() => setLoading(false)}
        />
      </div>

      {/* Content */}
      <div className="flex flex-col items-center justify-center px-5 py-10">
        <h2 className="mb-6 text-3xl font-bold">Junte-se a nós</h2>
        <a
          href="https://t.me/Managerstreamer"
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackButtonClick} // Track button click here
          className="mb-2 w-3/5 rounded-lg bg-blue-600 py-3 text-center font-semibold hover:bg-blue-700 md:w-2/4"
        >
          Candidate-se agora
        </a>
        <p className="mb-6 text-sm">
          Explore oportunidades emocionantes conosco
        </p>
        <p className="text-center text-lg">
          Destrave o potencial para ganhar até R$50.000 por mês com salário
          diário mais comissões lucrativas. Transmita 2-3 horas diárias e
          transforme seus sonhos em realidade. Alcance ganhos diários de R$5.000
          e rendimentos mensais de R$150.000 — está ao seu alcance. Entre em
          contato conosco para saber mais e começar a ganhar!
        </p>
      </div>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=774703414799892&ev=PageView&noscript=1`}
        />
      </noscript>
    </section>
  );
};

export default Hero;
