import React, { useState } from "react";
import Ticker from "framer-motion-ticker";

import receiptImg1 from "../assets/images/1.png";
import receiptImg2 from "../assets/images/2.png";
import receiptImg3 from "../assets/images/3.png";
import receiptImg4 from "../assets/images/4.png";
import receiptImg5 from "../assets/images/5.png";
import receiptImg6 from "../assets/images/6.png";
import receiptImg7 from "../assets/images/7.png";
import receiptImg8 from "../assets/images/8.png";
import receiptImg9 from "../assets/images/9.png";
import receiptImg10 from "../assets/images/10.png";
import receiptImg11 from "../assets/images/11.png";
import receiptImg12 from "../assets/images/12.png";
import receiptImg13 from "../assets/images/13.png";
import receiptImg14 from "../assets/images/14.png";
import receiptImg15 from "../assets/images/15.png";
import receiptImg16 from "../assets/images/16.png";
import receiptImg17 from "../assets/images/17.png";
import receiptImg18 from "../assets/images/18.png";
import receiptImg19 from "../assets/images/19.png";
import receiptImg20 from "../assets/images/20.png";
import receiptImg21 from "../assets/images/21.png";
import receiptImg22 from "../assets/images/22.png";
import receiptImg23 from "../assets/images/23.png";
import receiptImg24 from "../assets/images/24.png";
import receiptImg25 from "../assets/images/25.png";
import receiptImg26 from "../assets/images/26.png";

const images = [
  receiptImg1,
  receiptImg2,
  receiptImg3,
  receiptImg4,
  receiptImg5,
  receiptImg6,
  receiptImg7,
  receiptImg8,
  receiptImg9,
  receiptImg10,
  receiptImg11,
  receiptImg12,
  receiptImg13,
  receiptImg14,
  receiptImg15,
  receiptImg16,
  receiptImg17,
  receiptImg18,
  receiptImg19,
  receiptImg20,
  receiptImg21,
  receiptImg22,
  receiptImg23,
  receiptImg24,
  receiptImg25,
  receiptImg26,
].map((src, index) => ({ src, alt: `receipt ${index + 1}` }));

const Showcase = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  return (
    <section className="mb-36 text-slate-200">
      <div className="px-5 py-10 text-center">
        <h2 className="mb-4 text-3xl font-bold">
          Showcase de Sucesso de Streamers
        </h2>
        <Ticker
          duration={50}
          onMouseEnter={() => setIsPlaying(false)}
          onMouseLeave={() => setIsPlaying(true)}
          isPlaying={isPlaying}
          className="flex w-full justify-center"
        >
          {images.map((image, index) => (
            <div key={index} className="m-2 w-52 flex-shrink-0">
              <img
                src={image.src}
                alt={image.alt}
                className="h-full w-full rounded-lg object-cover"
              />
            </div>
          ))}
        </Ticker>
      </div>
    </section>
  );
};

export default Showcase;
